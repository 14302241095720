.portfolio-example-list {
    grid-template-columns: 1fr !important;
}

@media only screen and (min-width: 675px) {
    .portfolio-example-list {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media only screen and (min-width: 1200px) {
    .portfolio-example-list {
        grid-template-columns: repeat(3, 1fr) !important;
    }
}

@media only screen and (min-width: 1200px) {
    .portfolio-example-list-item-title {
        opacity: 0;
        transition: 325ms ease opacity;
    }
}

.portfolio-example-list-item:hover > .portfolio-example-list-item-title {
    opacity: 1;
}