.skill-item {
    flex-direction: column;
    display: flex;
    position: relative;
    margin-top: 2rem;
    max-width: 100%;
}

.skill-title {
    color: white;
    text-transform: uppercase;
}

@media only screen and (min-width: 1200px) {
    .skill-item {
        flex-direction: row;
        margin-top: 1.75rem;
    }
    .skill-title {
        position: absolute;
        left: 0;
        transform: translateX(calc(-100% - 1rem));
    }
}