.fab {
    display: inline-flex !important;
}
.fab-extended {
    display: none !important;
}

.fab, .fab-extended {
    background-color: ghostwhite !important;
}

.fab:hover, .fab-extended:hover {
    background-color: var(--primary-light) !important;
    color: ghostwhite !important;
}

#next, #previous {
    position: inherit;
}

@media screen and (min-width: 900px) {
    .fab {
        display: none !important;
    }
    .fab-extended {
        display: inline-flex !important;
    }
}