.MuiCard-root {
    max-width: 90%;
    color: white !important;
    background-color: var(--primary-dark) !important;
    text-align: center;
}

@media only screen and (min-width: 675px) {
    .MuiCard-root {
        max-width: 500px;
        padding: 1.5rem;
    }
}

@media only screen and (min-width: 1200px) {
    .MuiCard-root {
        max-width: 800px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 3rem; 
        text-align: left;
    }
}

.contact-form-content {
    display: flex;
    flex-direction: column;
}

@media only screen and (min-width: 1200px) {
    .contact-form-content {
        flex-direction: row;
    }
}

.contact-form-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
}

@media only screen and (min-width: 675px) {
    .contact-form-text {
        padding: 2rem;
    }
}

@media only screen and (min-width: 1200px) {
    .contact-form-text {
        padding: 2rem 5rem 2rem 2rem;
    }
}

.contact-form-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    justify-content: center;
}

.contact-form-form .MuiInputLabel-root {
    color: var(--secondary);
}

.contact-form-form .MuiInputLabel-root.Mui-focused {
    color: white;
}

.contact-form-form .MuiFilledInput-root:after {
    border-bottom: 2px solid var(--secondary);
}

.contact-form-form .MuiFilledInput-input {
    color: var(--secondary);
}

@media only screen and (min-width: 1200px) {
    .contact-form-form {
        padding-right: 1.5rem;
    }
}

a {
    text-decoration: none;
}

.contact-form-combined-inputs {
    flex-direction: column;
}

@media only screen and (min-width: 1200px) {
    .contact-form-combined-inputs {
        flex-direction: row;
    }
}