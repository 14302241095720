.fullHeight {
  height: calc(100vh - 56px - 70px);
  width: 100%;
}

@media (min-width: 600px) {
  .fullHeight {
    height: calc(100vh - 64px - 90px);
  }
}

.video-background {
  position: absolute;
  width: 100%;
  height: calc(100vh - 56px);
  inset: 0;
  object-fit: cover;
  z-index: -1;
}

@media (min-width: 600px) {
  .video-background {
    height: calc(100vh - 64px);
  }
}