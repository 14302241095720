.rotate-img {
  position: relative;
  animation: logo-rotate-clockwise 50s linear infinite;
  padding-right: 2vw;
  background: url("/src/assets/images/logo/logo_OuterRing.webp");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 33vw;
  height: 25vw;
}

.rotate-img::before {
  content: "";
  position: absolute;
  inset: 0;
  animation: logo-rotate-counter-clockwise 25s linear infinite;
  padding-right: 2vw;
  background: url("/src/assets/images/logo/logo_SecondRing.webp");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 33vw;
  height: 25vw;
}

.rotate-img::after {
  content: "";
  position: absolute;
  inset: 0;
  animation: logo-rotate-clockwise 30s linear infinite;
  padding-right: 2vw;
  background: url("/src/assets/images/logo/logo_CenterRing.webp");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 33vw;
  height: 25vw;
}

@keyframes logo-rotate-clockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes logo-rotate-counter-clockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@media only screen and (max-height: 800px) {
  .welcome {
    display: none;
  }
}
