.personal-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8rem;
}

@media only screen and (max-width: 1200px) {
    .personal-section {
        padding: 4rem;
    }
}

@media only screen and (max-width: 675px) {
    .personal-section {
        padding: 4rem 1rem;
    }
}

.personal-section:nth-child(1) {
    background-position: center;
    background-size: cover;
    background-blend-mode: soft-light;
    min-height: calc(100vh - 56px);
}

.personal-section:nth-child(2) {
    background-position: center;
    background-size: cover;
    background-blend-mode: soft-light;
    min-height: calc(100vh);
}

.personal-section:nth-child(3) {
    background-position: center;
    background-size: cover;
    background-blend-mode: soft-light;
    min-height: 2900px;
}

.personal-section:nth-child(4) {
    background-position: center;
    background-size: cover;
    background-blend-mode: soft-light;
    min-height: 550px;
}

.personal-section-content {
    max-width: 95%;
}

.personal-section:not(:first-of-type) > .personal-section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.personal-section-spacer {
    margin-top: 5rem;
}

@media only screen and (min-width: 675px) {
    .personal-section-spacer {
        margin-top: 10rem;
    }
}

@media only screen and (min-width: 1200px) {
    .personal-section-spacer {
        margin-top: 15rem;
    }
}

.personal-main-title {
    color: white;
    text-transform: uppercase;
}

.personal-main-title > h1 {
    font-weight: 600;
    font-size: min(15vw, 6rem);
    letter-spacing: .15em;
}

.personal-main-subtitle {
    color: white;
    text-transform: uppercase;
    margin-top: 4rem;
}

.personal-main-subtitle > h5 {
    letter-spacing: .3em;
    font-size: min(3.5vw, 1.5rem);
}

.personal-title-number {
    display: inline;
    color: var(--secondary);
    font-weight: 800 !important;
    letter-spacing: .15em !important;
    font-size: min(7.5vw, 3rem) !important;
}

.personal-title-text {
    display: inline;
    color: white;
    text-transform: uppercase;
    font-weight: 800 !important;
    letter-spacing: .15em !important;
    margin-left: 1.5rem !important;
    font-size: min(7.5vw, 3rem) !important;
}

.personal-subtitle {
    color: white;
    line-height: 1.8em !important;
    letter-spacing: 0.15em !important;
    text-transform: uppercase;
    margin-top: 1.3rem !important;
    font-size: min(3.5vw, 1.5rem) !important;
}

.personal-subtitle > * {
    display: inline;
}

.personal-section:nth-of-type(3) .personal-subtitle > h5 {
    letter-spacing: 0.15em !important;
    line-height: 1.8em !important;
    font-size: min(3.5vw, 1.5rem) !important;
}

.personal-link {
    color: var(--secondary);
    text-transform: uppercase;
    cursor: pointer;
    font-size: min(3.5vw, 1.5rem) !important;
}