:root {
    --primary: #4d5979;
    --primary-light: #5b688d;
    --primary-darker: #414b68;
    --primary-dark: #323a4f;
    --primary-darkest: #1e2332;
    --secondary: #BEC7D5;
    --accent: #00D646;
    --accent-dark: #03a639;
    --logo-secondary: #BF571B;
    --text-title: #5b6c7d;

    --tran-dur: 0.3s;
}

@font-face {
    font-family: matrixFont;
    src: url(./assets/fonts/matrixFont.ttf);
}

@font-face {
    font-family: logoFont;
    src: url(./assets/fonts/Lato-Light.ttf);
}

@font-face {
    font-family: questionTitle;
    src: url(./assets/fonts/Montserrat-ExtraBold.ttf);
}

@font-face {
    font-family: questionAnswer;
    src: url(./assets/fonts/Montserrat-Medium.ttf);
}

body {
    background: rgb(127,136,151);
    background: linear-gradient(180deg, rgba(127,136,151,1) 20%, var(--primary) 100%);
    font-family: Roboto;
}

*, *::before, *::after {
    margin: 0;
    box-sizing: border-box;
}

* {
    transform: translateZ(0);
}

.color-accent {
    color: var(--accent);
    display: inline;
}