.footer {
    color: white;
    background-color: hsl(215deg 54% 7% / 73%);
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 600px) {
    .footer {
        height: 90px;
    }
  }