.masonry-img {
    border-radius: 4px;
    display: block;
    width: 100%;
    min-height: 10px;
}

.museum-progress {
    margin-bottom: 20px;
}
