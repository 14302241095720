.minFullHeight {
  min-height: calc(100vh - 56px - 70px);
}

@media (min-width: 600px) {
  .minFullHeight {
    min-height: calc(100vh - 64px - 90px);
  }
}

@media (min-width: 1600px) {
  #question-paper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-image: url(../../assets/images/quiz/calculator.svg), url(../../assets/images/quiz/headphone.svg), url(../../assets/images/quiz/laptop.svg), url(../../assets/images/quiz/smartphone.svg), url(../../assets/images/quiz/keyboard.svg), url(../../assets/images/quiz/router.svg);
    background-position: left .5rem top 2rem, left 13rem top 40%, left 12rem bottom 1rem, right 8rem top 3.5rem, right 2rem center, right 13rem bottom 4rem;
    background-repeat: no-repeat;
    background-size: 170px, 150px, 130px, 130px, 120px, 120px;
    /* z-index: -1; */
  }
}