@media only screen and (max-width: 675px) {
    .vertical-timeline-element {
        max-width: 95%;
    }
}

.vertical-timeline-element-content {
    box-shadow: 0 .3em .55em 0 rgba(0, 0, 0, .25), 0 .45em .3em 0 rgba(0, 0 , 0, .15) !important;
    padding: 2em 3em !important;
}

.vertical-timeline-element-date {
    color: var(--secondary);
}

.vertical-timeline-element-description {
    margin: 1.5em 0 2em 0 !important;
}

.work-button {
    background-color: #228be6 !important;
}

.work-button:hover {
    background-color: #117ad6 !important;
}

.school-button {
    background-color: #e87e22 !important;
}

.school-button:hover {
    background-color: #d66d11 !important;
}

.moving-button {
    background-color: #0ead46 !important;
}

.moving-button:hover {
    background-color: #0c953c !important;
}

@media only screen and (max-width: 1170px) {
    .vertical-timeline-element-date {
        display: block !important;
        float: none !important;
        color: rgb(44, 44, 44);
        margin-top: 1.5em;
    }
}